* {
    padding: 0;
    margin: 0;
}

.title {
    margin: 0 !important;
}

.form-control {
    height: calc(1.8rem + 2px) !important;
}

h5.title {
    margin-bottom: .5em !important;
    margin-top: 1em !important;
}

textarea.form-control {
    height: calc(6rem + 2px) !important;
}

/* Handling EnqForm & Followup Card width */
.followupcard {
    /* width: 50% !important; */
    padding: 1em 1.5em;
}

/* Handling padding of INPUTS */
.form-group select,
.form-group input {
    padding: 0em .7em !important;

}

.form-group select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;

}

.majorgrid h3 {
    font-size: 13px;
    font-weight: bold;
}


.frmgrp.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
    row-gap: .5em;
}

.frmgrp.grid .form-control {
    margin: 0;
}

.grid-3 {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: .5em;
}

.mainBtns {
    display: flex;
    justify-content: center;

}

/* ---------------------------------------------------- */

/* Followup form */
.followupform {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5em;
    width: 100%;
}

.fudate {
    grid-column: 1/2;
}

.fucomments {
    grid-row: 3/4;
    grid-column: 1/3;
}

/* ---------------------------------------------------- */
/* Create Package Card */
.createpackage-card {
    max-width: 45%;
}

.addmorebtn {
    width: 100%;
}

.gridspan {
    grid-column: 1/3;
}

.gridspan button {
    margin-top: -1em;
}

.labels.gridspan {
    grid-template-columns: repeat(6, 1fr);
    column-gap: .5em;
}

.inputs.gridspan {
    grid-template-columns: repeat(6, 1fr);
    column-gap: .5em;
}

.colspan {
    grid-column: 1/3;
}

/* -_____________________________________________________________________________ */
/* Login Page CSS */
.loginCard {
    display: grid;
    width: 100%;
    height: 100vh;
    place-items: center;
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
    background-color: #32325292;
    padding: 1em 1.5em;
    border-radius: 5px;
    width: 400px;
}

.buttons-div {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: .6em;
}

.formHeadings {
    font-size: 14px;
   margin-bottom: .5em !important;
}

.formheadings.login {
    margin-top: 0;

    font-size: 22px;
    margin-bottom: 1em;
}

.radio-btn {
    margin-right: .4em;
}

.radio-btn+label {
    padding-right: 1em !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
}

.radio-btn:checked+label {
    color: white;
}

/* _______________________________________________________________________ */
/* MODAL SLIDER CSS */
.modalslider::-webkit-scrollbar {
    display: none;
}

.modalslider {
    position: fixed;
    margin-left: auto;
    left: 0;
    right: 0;
    display: none;
    top: 0;
    bottom: 0;
    /* background-color: white; */
    z-index: 10000;
    overflow-y: scroll;
    /* background-color: rgba(0, 0, 0, 0.4); */
    backdrop-filter: blur(1px);



}

.modalslider.showing {
    display: block !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(100%, 0px);
    }

    100% {
        opacity: 1;
        transform: translate(0%, 0px);

    }
}

.modalcontent {
    position: fixed;
    top: 0;
    /* width: max-content !important; */
    right: 0 !important;
    bottom: 0 !important;
    animation: fadeIn .2s;
    /* background-color: #27293d; */
    /* min-width: 500px !important; */
}

.modalcontent .followupcard {
    min-height: 100vh;
}

.modalcontent div {
    box-shadow: none !important;

}


.dropdown-menu .nav-link {
    padding: 0 !important;

}

.dropdown-menu .nav-link .dropdown-item,
.dropdown-menu .dropdown-divider {
    margin: 0 !important;

}

.dropdown-toggle.nav-link,
td .nav-link {
    padding: 0 .5em !important;
}

.navbar-expand-lg .navbar-nav {
    align-items: center !important;
}

/* mappedlinkstyles */
.mappedlink {
    /* color: rgb(226, 226, 226); */

    padding: .5em .3em;
    text-align: center;
    /* margin: 0 .3em; */
    font-size: 12px !important;
    transition: all .2s ease-out;
    font-weight: 500;
    color: rgb(57, 56, 56) !important;
    border: 2px solid var(--info);
    border-radius: 5px;
    margin-right: .75em;

}

.mappedlink:first-of-type {
    padding: .5em 2em;
}

.mappedlink:hover {
    color: var(--info) !important;
    /* border-bottom: 1px solid  rgb(255, 0, 255);; */
}

.mappedlink.activated {
    color: #fff !important;
    background-color: var(--info);
    border-radius: 2.5px;
    text-align: center;
    /* border-bottom: 1px solid  rgb(255, 0, 255);; */
}



/* LOGIN PAGE CSS */
.login-container {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}

.partleft {
    background: url('https://images.unsplash.com/photo-1436491865332-7a61a109cc05?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1174&q=80'), #020018bc;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: -500px 0px;
    width: 45%;
    color: white;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.leftheading {
    color: white;
    font-weight: 600;
    padding-right: .4em;
    font-size: 34px;
}

.leftsubheading {
    color: white !important;
    margin-top: -1em;
}

.partright {
    background-color: rgb(248, 252, 255);
    width: 60%;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 5em;
}

.loginform-container {
    width: 60%;
    padding: 1.5em;
}

.loginform-container div {
    margin-bottom: 1.5em;
    margin-top: 0;
    width: max-content;

}

.loginform-container div input {
    border-radius: 2px;
    width: 350px;
    height: 50px;
    font-size: 14px;
    padding: 0 1em;
}

.loginform-container div label {
    font-weight: 600;
    margin-bottom: .4em;
}

.rightheading {
    color: #525f7f !important;
    font-weight: 600;
}

.rightsubheading {
    color: #7e8595 !important;
    padding-bottom: .5em;
    border-bottom: 1px solid #c0c4cd;
    width: 350px;
    margin-bottom: 1.5em;
}

.rightsubheading.alerted {
    color: red !important;
    border-color: red;
}

.login-logo {
    margin-bottom: 2em;
    width: auto;
    height: 100px;
}

.loginbutton {
    padding: .8em 1.5em;
    border: none !important;
    border-radius: 2px;
    margin-top: 1em;
    width: 120px;
    background-color: var(--info);
    color: white;
    font-weight: 500;
    letter-spacing: .5px;
    transition: all .2s ease;
}

.forgot {
    display: block;
    width: 350px;
    text-align: right;
    color: var(--blue) !important;
    font-weight: 600;
    margin-top: -.5em;
    cursor: pointer;
}

.loginbutton:hover {
    background-color: var(--primary);
}

/* View Package CSS */
.itenaryNavigation {
    display: flex;
    align-items: center;

}

.itenaryNavigation div {
    margin-right: 1.5em;
    background-color: var(--info);
    color: white;
    padding: .5em 1em;
    border-radius: 3px;
    cursor: pointer;

}

.itenaryView {
    /* display: flex; */
    width: 100%;
    /* background-color: #bac9ed58; */
    border-radius: 5px;
    /* padding: 1em; */
    margin-top: .75em;
}

.itHotel {

    padding: 0 1em;
    border: 1px dashed var(--info);
}

.itAttraction {
    margin-top: 1em;
    padding: 1em 1em;
    border: 1px dashed var(--info);
}


.dKnGwB {
    min-height: 50px !important;
}




/* Package Card CSS */
.packageCard {
    padding: 0;
    margin: 0;

}

.alert-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em !important;
    /* background-color: #1a1e34 !important; */
    /* border: 2px solid white !important; */
}

.img-text {
    display: flex;
}

.pkgimg {
    width: 260px;
    width: 200px;
    height: 130px;
    object-fit: cover;
    margin-right: 1em;

}

.titledesc {
    align-self: flex-start;
}

.titledesc p {
    line-height: 1.8;
    min-width: 30ch;
    max-width: 42ch;
    max-height: 100%;
    margin: 0;

}

.pkgheading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .75em;
    /* color: rgba(255, 255, 255, 0.6); */
}

.btns {
    font-size: 14px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.btn-primary.button {
    margin: .3em 0 !important;
}

.btn.button {
    margin: .3em 0 !important;
    width: 150px !important;
    font-size: 14px;
    padding: .6em;
    font-weight: 400;
}

.btn.button:last-of-type {
    margin-bottom: 0 !important;
}

.button {
    border: none;
    width: 150px;
    margin-bottom: .25em;
    padding: .5em;
    font-family: "Poppins";
    color: white;
    border-radius: 5px;
    font-size: 14px;
}

.price {

    font-size: 18px;
    /* color: rgba(255, 255, 255, 0.6); */
}

/* ------------------------------------------------------------------------ */
/* Booking Details */
.nav-tabs li {
    margin-right: 1.5em;
}

.nav-tabs li a {
    font-size: 14px;
    font-weight: 500;

}

.nav-tabs .active a {
    font-weight: 600;
}

.custom-card {

    border-color: #aaa;

    padding-bottom: 1.5em;
    border-radius: 5px;
    margin-top: -.9em;
    border-top: none;

}

.outsiderdiv .custom-card {
    border: none;
}

.slider .custom-card-grid {
    grid-template-columns: 1fr 1fr;
}

.custom-card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2em;



}

.grid-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
}

.grid-flex-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
}

.custom-card .formheadings {
    font-size: 14px;

}

.btn {
    font-size: 12px !important;
    padding: .75em 1.75em !important;
}

.bookingdetails {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.bookingdetails button {
    background-color: transparent;
    border: none;

}

.bookingdetails button:first-of-type {
    color: var(--blue);
}

.bookingdetails button:last-of-type {
    color: var(--red);
    margin-left: 1em;
}

.bookingdetails p {
    font-weight: 600;
    margin-right: 1.5em;
}

.bookingdetails span {
    font-weight: 400;
}

.stage {

    padding: .25em 1em;
    border: 2px solid #aaa;
    border-radius: 5px;
    text-align: center;
}

.stage+div {
    background-color: #aaa;
    width: 10%;
    height: 2px;

}

.passed {
    background-color: var(--green);
    color: white;
    border-color: var(--green);
}

.passed+div {
    background-color: var(--green);
}

/* Create Package Form */
.form-control.upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: .1em !important;
    padding-left: .75em !important;
    color: rgb(154, 154, 154) !important;
    font-size: 12px;
}

.form-control.upload .btn {
    margin: 0;
    min-width: 30%;
}

.slider {
    height: 100vh;
    padding: 1em;

}

.customtable {
    margin: 1em 0;
}

.customtable th,
td {
    padding: .5em;

}

td button {
    background-color: transparent;
    border: none;
    padding: 0 1em;
}


.jLJMJb {
    padding: 0 !important;
}

.kqSjre.right {
    width: 100px;
    white-space: nowrap;
}

#timeline-main-wrapper {
    padding: 0;
}

.react-tabs__tab-panel--selected {
    border: 1px solid #aaa;
    border-top: none;
    margin-top: -.75em;
    padding: 1.5em 1em;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

td button {
    background-color: transparent;
    border: none;
    padding: 0 1em;
}

/* Itenary Builder */
.hotelmini {
    display: flex;
    border-radius: 5px;
    background-color: white;
    background-color: whitesmoke;
    padding: .5em;
    margin-bottom: 1em;

}

.hotelmini div:last-of-type {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
}

/*Paging */
/*  */
.page-link {
    color: #6c757d !important;
}

.page-item.active .page-link {
    color: white !important;
    background-color: var(--info) !important;
    border-color: var(--info) !important;

}


.fixed-plugin {
    display: none !important;
}

ul a {
    cursor: pointer;
}



.mce-edit-focus :focus-visible {
    border: none !important;
    outline: none !important;
}


.name1 {
    color: red !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}


.newenq {
    background-color: var(--primary) !important;
    color: white !important;
    font-weight: 600;
    text-align: left;
    padding: .25em .5em;
    margin: .25em 0;
}

.inprogressenq {
    background-color: var(--info) !important;
    color: white !important;
    font-weight: 600;
    text-align: left;
    padding: .25em .5em;
    margin: .25em 0;
}

.pendingenq {
    background-color: var(--green) !important;
    color: white !important;
    font-weight: 600;
    text-align: left;
    padding: .25em .5em;
    margin: .25em 0;
}

.completedenq {
    background-color: var(--green) !important;
    color: white !important;
    font-weight: 600;
    text-align: left;
    padding: .25em .5em;
    margin: .25em 0;
}

.number {
    text-align: center;
    font-weight: 600;
    color: #525f7f !important;
}

.calendar {
    display: flex;
    max-width: 100%;
    min-width: 100%;
    background-color: red;
    padding: 1em;
}

.calendarDay {
    width: 100%;
    background-color: #7e8595;
    margin-left: 1em;
}

.vertical-timeline-element--work{
    margin-left: 0 !important;
}
.vertical-timeline-element{
    margin-left: 0 !important;
}

.vertical-timeline {
    margin: 0 !important;
}

.rbt-input-wrapper{
    margin-top: -6px !important;
}

  .rbt-input-multi.form-control
  {
    height: auto !important;
  }

.sidebar,
.off-canvas-sidebar {
  background: #ba54f5;
  overflow: hidden !important;
  background: -webkit-linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
  background: -o-linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
  background: -moz-linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
  background: linear-gradient(0deg, #ba54f5 0%, #e14eca 100%);
  height: calc(100vh - 90px);
  width: 250px !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  display: block;
  box-shadow: 0px 0px 45px 0px rgba(0, 0, 0, 0.6);
  margin-top: 80px;
  margin-left: 0px !important;
  border-radius: 5px;
  transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.sidebar .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a i
{
    overflow: hidden !important;

}

.main-panel
{
    float: none!important;
}
.main-panel > .content
{
    padding: 78px 10px 10px 15px !important;        
    height:calc(100vh - 400px) !important;
}

.a{
    color: #525f7f !important;
}

.ps-menu-button a{
    color: #59d0ff !important;
}

a {
    color: #1d3163 !important;
}
.navbar.navbar-absolute{
    z-index: 1 !important;
}
.css-go2k4k{
    padding-top: 15px !important;
}

.text-red-400{
    color: rgb(248 113 113);
}

.wrapper{
 display: flex;   
}


